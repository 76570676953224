import { template as template_0d1d8ec55d1942a8b21f4907244aabcf } from "@ember/template-compiler";
const FKLabel = template_0d1d8ec55d1942a8b21f4907244aabcf(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
