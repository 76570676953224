import { template as template_769878da377240eeb20296cf3dc6aca0 } from "@ember/template-compiler";
const WelcomeHeader = template_769878da377240eeb20296cf3dc6aca0(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
